<script setup lang="ts">
defineEmits<{
  (e: 'click:close', id: number): void;
}>();

type NotificationType =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'secondary'
  | 'danger';

const props = defineProps<{
  notification: {
    type: NotificationType;
    message: string;
    id: number;
    icon?: string;
  };
}>();

const iconsMap = {
  info: 'NOTIFICATION',
  success: 'SUCCESS',
  warning: 'SHIELD',
  error: 'SHIELD',
  secondary: '',
  danger: 'SHIELD',
}; // ADDED_TO_CART

const icon = computed(
  () =>
    props.notification.icon ||
    iconsMap[props.notification.type] ||
    'information'
);
</script>

<template>
  <Notification
    :id="notification.id"
    :message="notification.message"
    :type="notification.type"
    :icon-name="icon"
    @click="$emit('click:close', notification.id)"
  />
</template>
